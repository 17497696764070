import { FieldsData } from "./types";
import { decodeFieldsFromText } from "./utils";

export const useSuccessStep = (
    successMessage: string,
    fieldsState: FieldsData | null,
    isSecondServiceForm: boolean,
) => {
    if (isSecondServiceForm) {
        const formattedSuccessMessage = decodeFieldsFromText(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            JSON.parse(localStorage.getItem("successMessage") as string),
            fieldsState,
        );
        return formattedSuccessMessage;
    }
    const formattedSuccessMessage = decodeFieldsFromText(
        successMessage,
        fieldsState,
    );
    return formattedSuccessMessage;
};
