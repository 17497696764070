/* eslint-disable react-hooks/rules-of-hooks */
import {
    Field,
    ListingItem,
    PopupProps,
    ExpandableFieldMeta,
    Colors,
    ClickOffer,
    SecondServiceColors,
} from "@lib/shared/types";
import React, {
    CSSProperties,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from "react";
import dynamic from "next/dynamic";
import styles from "./styles.module.scss";
import ProgressBar from "./progressBar";
import Loader from "@components/shared/loader";
import ArrowRightIcon from "@components/svg/arrowRightIcon";
import Modal from "../modal";
import CloseIcon from "@components/svg/closeIcon";
import OtpForm from "./OtpForm";
import flatten from "lodash/flatten";
import { useDomainContext } from "@hooks/useDomainContext";
import { useForm } from "@hooks/useForm";
import { useFormContext } from "@hooks/useFormContext";
import { useEffect } from "react";
import { useRouter } from "next/router";
import {
    handlePreventPageClose,
    doesOfferHaveALink,
    extractContentFromHtml,
} from "@lib/sharedUtils";

import TCPA from "./TCPA";
import CompactOffer from "@components/shared/compactOffer";
import OfferBlock from "@components/shared/offer";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import WarningAlertIcon from "@components/svg/warningAlertIcon";
import { affiliateOfferZones } from "@lib/shared/config";
import OffersInForm from "./offersInForm";
import { DomainForm, getClicksSearchList, getListingBySlugV3 } from "src/api";
import InsuranceOffer from "../insuranceOffer";
import { RegulationsContent } from "../regulationsContent/RegulationsContent";
import SecondServiceForms from "./secondService";
import { generateGlobalVariables } from "@page-builder/utils";
import ThankYou from "../thankyou";
import FieldWrapper from "./fieldWrapper";
import preDefinedAnswerSummaryConfigs from "./answerSummary/preDefinedConfigs";
import { Variable } from "@page-builder/ts/generated";
import { cloneDeep } from "lodash";
import FormOfferModal from "../formOfferModal/FormOfferModal";
import { FormStep } from "./formStep";
import { FormField } from "./formField";
import { FieldData } from "@hooks/useForm/types";
import { BuyersList } from "./buyersList";
// import axios from "axios";
const PopupCallUs = dynamic(() => import("@components/shared/popupCallus"));

let modalWidth = 900;
export interface Classes {
    formClassName?: string;
    stepClassName?: string;
    stepTitleClassName?: string;
    stepDescriptionClassName?: string;
    formFieldClassName?: string;
    formButtonClassName?: string;
    formStepInnerClassName?: string;
    fieldsClassName?: {
        all?: string;
        radio?: string;
        textarea?: string;
        input?: string;
        date?: string;
        select?: string;
        multiSelect?: string;
        checkbox?: string;
        zipcodeExpandable?: string;
        streetAddress?: string;
        range?: string;
    };
    fieldsWrapperClassName?: string;
    fieldNoteClassName?: string;
    tcpaClassName?: string;
    labelAsTitleClassName?: string;
    roundedLabelShift?: string;
    noteIndent?: string;
    formFieldLabelClassName?: string;
    formProgressbarClassName?: string;
    sellingPointClassName?: string;
    formSubmitButtonClassName?: string;
    formFieldDescriptionClassName?: string;
    fieldErrorMessageClassName?: string;
    stepTagClassName?: string;
    firstStepClass?: string;
    innerThankYouClassName?: string;
}
export interface StepsConfig {
    stepOrder?: number;
    hideTitle?: boolean;
    hideDescription?: boolean;
    forceStepTitle?: boolean;
    title?: string;
    description?: string;
    hideProgress?: boolean;
}
export type AnswerSummaryConfigs = AnswerSummaryConfig[];
export interface AnswerSummaryConfig {
    // step index to show the summary
    stepIndex: number;
    // fields to show in the summary
    fields: {
        codeName: string;
        gridArea: string;
        placeSelf?: Variable;
        className?: string;
        appendText?: string;
    }[];
    // styling
    gridTemplateAreas: Variable;
    gridTemplateColumns: Variable;
    gridTemplateRows: Variable;
    gap?: Variable;
    // if true then show summary until end of form
    extend?: boolean;
}

export interface FormProps {
    classes?: Classes;
    secondServiceClasses?: Classes;
    colors: Colors;
    secondServiceColors?: SecondServiceColors;
    fieldBorderRadius?: number;
    scrollToTop?: boolean;
    showProgress?: boolean;
    backButton?: {
        class?: string;
        label?: string;
        firstStepAction?: () => void;
    };
    sellingPoint?: string;
    sellingPointsSteps?: "ALL_STEPS" | "FIRST_STEP";
    customMainTitle?: string;
    preventWindowClose?: boolean;
    progressShift?: number;
    goToThankYouPage?: boolean;
    stepsConfig?: StepsConfig[] | undefined;
    dynamicThankYouFields?: string[];
    // PopupOffer?: ReactElement;
    popupStyle?: PopupProps;
    modalSize?: number;
    currentCategorySlug?: string;
    hideOffers?: boolean;
    ignoreOptionIcons?: boolean;
    customThankYouStep?: ReactElement;
    form?: DomainForm;
    isSecondServiceForm?: boolean;
    isPiiCompletionForm?: boolean;
    updateSecondServices?: (slug: string) => void;

    tags?: { [x: string]: { slug: string; active: boolean } };
    activeSecondServices?: string[];
    styles?: { [x: string]: string };
    secondServiceLoading?: boolean;
    showStepNumberOnly?: boolean;
    formTemplateProp?: "v1" | "v2";
    resetLeadId?: boolean;
    searchableMultiSelect?: boolean;
    secondServiceSearchableMultiSelect?: boolean;
    showInModal?: boolean;
    onModalClickClose?: () => void;
    answerSummaryConfigs?: AnswerSummaryConfigs;
    ignoreCategoryInThankYouPagePath?: boolean;
    isFormInsideOffersModal?: boolean;
    goToListPage?: boolean;
}
export interface StreetAddress {
    streetNumber?: string;
    route?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    state?: string;
}

let popupShowCount = 0;

export interface MutationValue {
    tagName: string;
    outerHTML: string;
    type: string;
    domain: string;
}

export default function Form(props: FormProps): ReactElement {
    const {
        form,
        domain,
        category,
        formTemplate,
        formOffer,
        visitDetails,
        exitModalOffer,
        locale,
        piiCompletionData,
    } = useDomainContext();

    const {
        setSetPopupFormSubmittedFunction,
        skipToThankYou,
        setSecondServiceSubmitted,
        setGoToThankYouPage,
    } = useFormContext();

    const router = useRouter();
    const [offersPopupActive, setOffersPopupActive] = useState(false);
    const [popupOffers, setPopupOffers] = useState<ListingItem[]>([]);

    const [clicksOffers, setClicksOffers] = useState<
        ClickOffer[] | null | undefined
    >([]);
    const [listRequestId, setListRequestId] = useState("");

    useEffect(() => {
        if (router.query.lander) {
            localStorage.setItem("lander", router.query.lander as string);
        }
        setGoToThankYouPage(props.goToThankYouPage ?? false);
    }, []);

    const compactOfferBlock = domain.defaultOffers?.find(
        (offer) => offer.category?.slug === category?.slug,
    );
    const { form: formAsProp } = props;
    const finalForm = formAsProp ?? form;
    if (finalForm) {
        const {
            classes,
            colors,
            showProgress,
            backButton,
            sellingPoint,
            sellingPointsSteps,
            preventWindowClose,
            progressShift,
            stepsConfig,
            goToThankYouPage,
            dynamicThankYouFields,
            // PopupOffer,
            scrollToTop = true,
            modalSize,
            currentCategorySlug,
            popupStyle,
            hideOffers,
            customThankYouStep,
            isSecondServiceForm,
            isPiiCompletionForm,
            secondServiceColors,
            secondServiceClasses,
            updateSecondServices,
            tags,
            activeSecondServices,
            secondServiceLoading,
            showStepNumberOnly,
            formTemplateProp,
            resetLeadId = false,
            searchableMultiSelect,
            secondServiceSearchableMultiSelect,
            onModalClickClose,
            ignoreCategoryInThankYouPagePath = false,
            isFormInsideOffersModal = false,
            goToListPage,
        } = props;

        const {
            handleFieldBlur,
            handleFieldChange,
            handleSubmit,
            handlePrevStep,
            formId,
            formError,
            jornayaIdElement,
            steps,
            currentStep,
            isLoading,
            fieldsData,
            successMessage,
            handleOutsideFormSubmit,
            formSubmitted,
            otp,
            checkIfStepHasOneField,
            visitedSteps,
            formErrors,
            formErrorsResolved,
            showExitModal,
            stepStatus,
            showRegulations,
            setLoading,
            setHasRegulations,
            showCustomListingModal,
            setShowCustomListingModal,
            showPiiCompletionOtherFields,
            setShowPiiCompletionOtherFields,
            showFormModal,
            toggleFormModal,
            listingSlug,
            toggleShowFormInExitModal,
            showFromInExitModal,
            handleCloseFormInsideExitModal,
            dispatch,
            startSecondRoundBidding,
            operation,
        } = useForm(
            finalForm,
            isSecondServiceForm,
            isPiiCompletionForm,
            goToListPage,
            resetLeadId,
            scrollToTop,
            !!props.showInModal,
            isFormInsideOffersModal,
        );

        const handleOffersPopupVisibility = () => {
            if (
                domain.config?.showFormInsideOffersModal &&
                !isFormInsideOffersModal &&
                popupShowCount === 0
            ) {
                toggleShowFormInExitModal(true);
                popupShowCount++;
                return;
            }
            const offers = exitModalOffer?.filter((offer) => {
                if (
                    (!category?.slug &&
                        props.currentCategorySlug &&
                        offer.category.slug === props.currentCategorySlug) ||
                    (category?.slug && offer.category.slug === category?.slug)
                ) {
                    return offer;
                }
            });

            if (
                offers?.length &&
                !offersPopupActive &&
                popupShowCount === 0 &&
                !showFromInExitModal
            ) {
                setPopupOffers(offers);
                setOffersPopupActive(true && !showFormModal);
                popupShowCount++;
            }
        };

        useEffect(() => {
            if (isSecondServiceForm && formSubmitted) {
                setSecondServiceSubmitted(true);
            }
        }, [formSubmitted]);

        const isSecondServiceSelected = useMemo(() => {
            if (tags) {
                const tagsKeys = Object.keys(tags);
                let tagsSelected = false;
                tagsKeys.forEach((tag) => {
                    tagsSelected = tagsSelected || tags[tag].active;
                });
                return tagsSelected;
            }
            return false;
        }, [tags]);

        const displayExitModal = useMemo(() => {
            if (showExitModal) {
                return true;
            }
            return false;
        }, [showExitModal]);

        const displayFormInOfferModal = useMemo(() => {
            return showFromInExitModal;
        }, [showFromInExitModal]);

        const fetchInsuranceOffers = async () => {
            const crossSellingDomainFormId = domain.categories.find(
                (item) => item.slug === category?.slug,
            )?.crossSellingDomainFormId;
            if (crossSellingDomainFormId) {
                setLoading(true);
                const { data: clicksSearchList } = await getClicksSearchList({
                    questions: {
                        zipCode: {
                            value: fieldsData["zipCode"].value,
                            name: "zipCode",
                        },
                    },
                    categorySlug: "auto-insurance",
                    crossSellingDomainFormId: crossSellingDomainFormId,
                });
                setLoading(false);
                return clicksSearchList;
            }
            return [];
        };
        const scrollToTopHandler = () => {
            if (scrollToTop) {
                window.scrollTo({
                    top: 22,
                    behavior: "smooth",
                });
            }
        };

        const fetchListingBySlug = async (slug: string) => {
            const { data } = await getListingBySlugV3({ slug });
            return data;
        };

        useEffect(() => {
            if (showRegulations) {
                fetchInsuranceOffers()
                    .then((res) => {
                        setOffersPopupActive(true);
                        setClicksOffers(res?.result as ClickOffer[]);
                        setListRequestId(res?.listRequestId as string);
                    })
                    .catch(() => {
                        setClicksOffers([]);
                        setOffersPopupActive(false);
                    });
            }
        }, [showRegulations]);

        useEffect(() => {
            if (showCustomListingModal && listingSlug) {
                fetchListingBySlug(listingSlug)
                    .then((res) => {
                        if (res && res.offers && res.offers.length) {
                            setOffersPopupActive(true);
                            setPopupOffers(res?.offers);
                        }
                    })
                    .catch(() => {
                        setPopupOffers([]);
                        setOffersPopupActive(false);
                    });
            }
        }, [showCustomListingModal]);

        useEffect(() => {
            if (displayExitModal || displayFormInOfferModal) {
                handleOffersPopupVisibility();
            }
        }, [displayExitModal, displayFormInOfferModal]);

        useEffect(() => {
            setSetPopupFormSubmittedFunction({
                function: handleOutsideFormSubmit,
            });

            document.body.addEventListener("mousemove", (e) => {
                const mouseY = e.clientY;
                if (
                    mouseY <= 10 &&
                    visitDetails?.trafficSourceNetwork !== "Propel Media"
                ) {
                    handleOffersPopupVisibility();
                }
            });

            if (
                preventWindowClose &&
                router.query.ts &&
                visitDetails?.trafficSourceNetwork !== "Propel Media"
            ) {
                window.addEventListener("beforeunload", handlePreventPageClose);
            }
        }, [currentCategorySlug]);

        const isLastStep: boolean = useMemo(() => {
            return currentStep === steps?.length - 1;
        }, [currentStep, steps]);

        useEffect(() => {
            if (isLastStep) {
                window.removeEventListener(
                    "beforeunload",
                    handlePreventPageClose,
                );
            }
        }, [isLastStep]);

        const goToThankYou = useCallback(
            (queryString, params) => {
                const isSingleCategory = domain?.categories?.length === 1;
                const categorySlug = category ? `${category.slug}/` : "";
                const activeLang = locale === "en" ? "" : `${locale}/`;

                const selectedCategory = domain.categories.find(
                    (categoryElement) =>
                        categoryElement.slug === form?.categorySlug,
                );
                let isSecondServiceSupported = false;
                if (
                    selectedCategory &&
                    selectedCategory?.secondServiceCategories?.length
                ) {
                    isSecondServiceSupported = true;
                }

                if (
                    isSingleCategory ||
                    isSecondServiceSupported ||
                    ignoreCategoryInThankYouPagePath
                ) {
                    return `${
                        window.location.origin
                    }/${activeLang}thank-you?data=${btoa(
                        JSON.stringify(params),
                    )}&zc=${fieldsData["zipCode"]?.value}&${
                        queryString as string
                    }`;
                }
                return `${
                    window.location.origin
                }/${activeLang}${categorySlug}thank-you?data=${btoa(
                    JSON.stringify(params),
                )}&zc=${fieldsData["zipCode"]?.value}&${queryString as string}`;
            },
            [domain, category, locale, fieldsData],
        );

        const saveGlobalAnswers = () => {
            if (!isSecondServiceForm) {
                const formAnswers = Object.keys(fieldsData).reduce(
                    (res, key) => {
                        res = {
                            ...res,
                            [key]: fieldsData[key].value,
                        };
                        return res;
                    },
                    {},
                );
                // @ts-ignore
                window.formAnswers = cloneDeep(formAnswers);
                localStorage.setItem(
                    "globalFormValues",
                    JSON.stringify(formAnswers),
                );
            }
        };

        useEffect(() => {
            const params: { [x: string]: string } = {};
            if (
                (formSubmitted &&
                    goToThankYouPage &&
                    !otp.isRequired &&
                    (!category?.secondServiceCategories ||
                        category?.secondServiceCategories.length === 0 ||
                        isSecondServiceForm)) ||
                (skipToThankYou && goToThankYouPage)
            ) {
                const queryString = gettingQueriesAsString(
                    router.query,
                    "start",
                );
                if (dynamicThankYouFields && !isSecondServiceForm) {
                    dynamicThankYouFields.forEach((field) => {
                        params[field] = fieldsData[field]?.value;
                    });
                }

                window.location.href = goToThankYou(queryString, params);
            }
        }, [
            formSubmitted,
            router,
            goToThankYouPage,
            dynamicThankYouFields,
            skipToThankYou,
            category,
        ]);
        useEffect(() => {
            const params: { [x: string]: string } = {};
            if (dynamicThankYouFields && !isSecondServiceForm) {
                dynamicThankYouFields.forEach((field) => {
                    params[field] = fieldsData[field]?.value;
                });
                localStorage.setItem("dynamicFields", JSON.stringify(params));
            }
            if (isPiiCompletionForm) {
                piiCompletionData?.fields.forEach((field) => {
                    params[field.codeName] = fieldsData[field.codeName]?.value;
                });
                localStorage.setItem("dynamicFields", JSON.stringify(params));
            }
            saveGlobalAnswers();
        }, [fieldsData]);

        useEffect(() => {
            if (!isSecondServiceForm) {
                localStorage.setItem(
                    "successMessage",
                    JSON.stringify(successMessage),
                );
            }
        }, []);

        const slideFormValue: number = useMemo(() => {
            // form errors step
            if (
                isLastStep &&
                formErrors
                // ||
                // (formErrorsResolved && !otp?.isRequired && !otp?.isResolved)
            ) {
                return currentStep * -100 - 100;
            }

            // buyers list step
            if (
                operation?.key === "ROUND_TWO" &&
                (!formSubmitted || (formSubmitted && goToThankYouPage))
            ) {
                return currentStep * -100 - 300;
            }

            // otp step
            if (
                otp?.isRequired ||
                (otp?.isResolved &&
                    goToThankYouPage &&
                    operation?.key !== "ROUND_TWO")
            ) {
                return currentStep * -100 - 200;
            }

            // thankyou step
            if (formSubmitted && !goToThankYouPage && !goToListPage) {
                return currentStep * -100 - 400;
            }

            // form steps (default)
            return currentStep * -100;
        }, [
            formSubmitted,
            goToThankYouPage,
            isLastStep,
            otp,
            currentStep,
            formErrorsResolved,
            formErrors,
            operation?.key,
        ]);

        const showOtp =
            otp?.isRequired || (!otp?.isRequired && otp?.isResolved);

        const showTCPAMessage: boolean = useMemo(() => {
            return (
                steps &&
                steps[currentStep]?.includeTCPAMessage &&
                !formSubmitted &&
                (!otp?.isRequired || operation?.key === "ROUND_TWO")
            );
        }, [formSubmitted, otp, operation?.key]);

        const showBackButton: boolean | undefined = useMemo(() => {
            return (
                (currentStep !== 0 && !formSubmitted && !otp.isRequired) ||
                (backButton?.firstStepAction && currentStep === 0)
            );
        }, [backButton, currentStep, steps, formSubmitted]);

        type FieldWithValue = Partial<Field> & FieldData;

        const invalidFields: FieldWithValue[] = useMemo(() => {
            const fields = flatten(steps.map((step) => step.fields));

            if (formErrors) {
                const allFields: string[] = Object.keys(fieldsData);

                const invalidFieldsArray: FieldWithValue[] = [];

                allFields.forEach((field) => {
                    if (fieldsData[field].valid === false) {
                        invalidFieldsArray.push({
                            ...fieldsData[field],
                            ...(fields.find((f) => f.codeName === field) ?? {}),
                        });
                    }
                });

                return invalidFieldsArray;
            }
            return [];
        }, [formErrors]);

        const doesTheOfferHaveANumber = (offer: ListingItem): boolean => {
            return !!(
                offer?.proxiedNumber?.phoneNumber ||
                offer?.hiddenAttributes?.phoneNumber ||
                offer?.phoneValue ||
                offer?.attributes.phoneNumber
            );
        };

        const showFormInsideOffersModal = (): boolean => {
            return (
                !!domain.config?.showFormInsideOffersModal &&
                !isFormInsideOffersModal
            );
        };

        const modalOffersContent = useMemo(() => {
            if (
                Array.isArray(popupOffers) &&
                popupOffers.length === 1 &&
                doesTheOfferHaveANumber(popupOffers[0]) &&
                popupOffers[0]?.features?.length === 0
            ) {
                modalWidth = 500;
                return (
                    <PopupCallUs
                        popupStyle={popupStyle}
                        offerAsProp={popupOffers[0]}
                    />
                );
            }
            return popupOffers.map((offer) => {
                return (
                    <OfferBlock
                        zone={affiliateOfferZones.EXIT_MODAL}
                        offer={offer}
                        key={offer.id}
                        className={styles["offer"]}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(offer)}
                    />
                );
            });
        }, [popupOffers]);

        const modalOfferClicksContent = useMemo(() => {
            if (showRegulations && clicksOffers && clicksOffers.length) {
                return (
                    <RegulationsContent
                        title="Hey Californians!"
                        description="Your state restricts extended auto
                                    warranties. You can get complete
                                    coverage by looking into mechanical
                                    breakdown insurance (MBI). Visit these
                                    websites to find out more:"
                    >
                        {clicksOffers?.slice(0, 2)?.map((clickOffer, index) => (
                            <InsuranceOffer
                                key={index}
                                index={index}
                                item={{
                                    ...clickOffer,
                                    slug: `${clickOffer.slug}?id=${listRequestId}`,
                                }}
                                category="Auto Insurance"
                                state={visitDetails?.region}
                                primaryColor="#f79626"
                                textColor="#266199"
                            />
                        ))}
                    </RegulationsContent>
                );
            }
        }, [clicksOffers]);

        const templateGlobalConfig = domain?.templateConfig;

        const modalVariables =
            typeof templateGlobalConfig?.headerAndFooter === "object" &&
            typeof templateGlobalConfig?.headerAndFooter.modals === "object"
                ? generateGlobalVariables({
                      modals: templateGlobalConfig?.headerAndFooter.modals,
                  })
                : {};

        const showOfferInForm =
            currentStep === 0 &&
            !!formOffer?.length &&
            (formTemplate === "v1" ||
                formTemplate === "v2" ||
                formTemplateProp);

        const isExpandable: (field: Field) => boolean = function (
            field: Field,
        ): boolean {
            const metaKeys: string[] = field.meta
                ? Object.keys(field.meta)
                : [];
            // check if field has expandable meta key
            return Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys[0] === "expandable"
                    ? true
                    : false
                : false;
        };

        const getExpandableFields: (field: Field) => Field[] = function (
            field: Field,
        ): Field[] {
            const filteredExpandableFields = Object.keys(
                JSON.parse(
                    field.meta?.expandable ?? "[]",
                ) as ExpandableFieldMeta,
            ).filter((f) => f !== field.codeName);

            const allFields: string[] = Object.keys(fieldsData);
            const arrayOfExpandableFields: Field[] = [];
            allFields.forEach((codeName) => {
                if (filteredExpandableFields.includes(codeName)) {
                    // get expandable field object
                    arrayOfExpandableFields.push(fieldsData[codeName]);
                }
            });

            return arrayOfExpandableFields;
        };

        const isSecondServiceSupported = useMemo(() => {
            const selectedCategory = domain.categories.find(
                (categoryElement) => categoryElement.slug === category?.slug,
            );
            if (
                selectedCategory &&
                selectedCategory?.secondServiceCategories?.length
            ) {
                return true;
            }
            return false;
        }, [category]);

        const submitButtonText = useMemo(() => {
            const secondStepButtonText = form?.textBlocks?.find(
                (item) =>
                    item.position ===
                    "form-inside-exit-modal-submit-button-text-second-step",
            );
            return secondStepButtonText?.text && isFormInsideOffersModal
                ? extractContentFromHtml(secondStepButtonText.text).trim()
                : steps[currentStep]?.buttonText;
        }, [form, isFormInsideOffersModal, currentStep]);

        const tcpa = useMemo(() => {
            const showTCPA =
                ((isSecondServiceForm && isSecondServiceSelected) ||
                    (!isSecondServiceForm && showTCPAMessage && !formErrors)) &&
                (!operation?.loadingBuyers ||
                    operation?.firstRoundBuyers?.length);

            const domainTcpa = domain?.textBlocks?.find(
                (textEl) => textEl.position === "custom-domain-tcpa",
            )?.text;

            if (showTCPA) {
                return (
                    <TCPA
                        content={
                            (domainTcpa
                                ? domainTcpa
                                : finalForm.tCPAMessages
                                ? finalForm.tCPAMessages
                                : steps[currentStep].TCPAMessage) ?? ""
                        }
                        className={classes?.tcpaClassName ?? ""}
                        buttonText={submitButtonText}
                        operation={operation}
                        dispatch={dispatch}
                    />
                );
            }
        }, [
            isSecondServiceForm,
            isSecondServiceSelected,
            showTCPAMessage,
            formErrors,
            isFormInsideOffersModal,
            currentStep,
            submitButtonText,
            domain,
            operation,
        ]);

        const { formSubmitted: isMainFormSubmitted } = useFormContext();

        if (
            (skipToThankYou && !goToThankYouPage) ||
            (formSubmitted && isSecondServiceForm && !goToThankYouPage)
        ) {
            scrollToTopHandler();
            return (
                <div className={`${classes?.formClassName as string} mx-auto`}>
                    {compactOfferBlock && !hideOffers && (
                        <CompactOffer
                            zone={affiliateOfferZones.THANK_YOU}
                            showAllOffers
                        />
                    )}

                    {customThankYouStep ?? (
                        <ThankYou
                            inForm={true}
                            className={classes?.innerThankYouClassName ?? ""}
                        />
                    )}
                </div>
            );
        }

        if (
            isSecondServiceSupported &&
            isMainFormSubmitted &&
            !isPiiCompletionForm &&
            !isSecondServiceForm &&
            !showFormModal
        ) {
            return (
                <SecondServiceForms
                    classes={secondServiceClasses}
                    colors={{ ...colors, ...secondServiceColors }}
                    goToThankYou={goToThankYouPage ? true : false}
                    secondServiceSearchableMultiSelect={
                        secondServiceSearchableMultiSelect
                    }
                />
            );
        }

        const finalAnswerSummaryConfigs = domain.config
            ?.preDefinedAnswerSummaryConfigs
            ? preDefinedAnswerSummaryConfigs[
                  domain.config?.preDefinedAnswerSummaryConfigs
              ]
            : undefined;

        return (
            <>
                {finalForm ? (
                    <>
                        {finalForm.testConfig?.config?.css && (
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: finalForm.testConfig?.config?.css,
                                }}
                            />
                        )}

                        <form
                            data-tf-element-role="offer"
                            onSubmit={(e) => {
                                handleSubmit(e, {
                                    isSecondForm: isSecondServiceForm
                                        ? true
                                        : false,
                                });
                            }}
                            className={`${classes?.formClassName ?? ""} ${
                                styles["form"]
                            }`}
                            style={{
                                color: colors.textColor,
                                ...(showFormModal
                                    ? {
                                          backgroundColor: "transparent",
                                          boxShadow: "none",
                                      }
                                    : {}),
                            }}
                            noValidate
                            id={`form_${formId}`}
                            method="post"
                        >
                            {showOfferInForm &&
                                (formTemplate === "v1" ||
                                    formTemplateProp === "v1") && (
                                    <OffersInForm
                                        offer={
                                            formOffer[0]
                                                ? formOffer[0]
                                                : undefined
                                        }
                                        shape={formTemplate || formTemplateProp}
                                    />
                                )}

                            <FieldWrapper
                                type={showFormModal ? "modal" : "div"}
                                classes={classes}
                                current={currentStep}
                                total={
                                    formSubmitted
                                        ? steps?.length
                                        : steps?.length + 1
                                }
                                colors={colors}
                                shift={progressShift}
                                showProgress={
                                    showProgress &&
                                    !otp?.isRequired &&
                                    !formErrors &&
                                    !showOfferInForm
                                }
                                showStepNumberOnly={showStepNumberOnly}
                                showBackButton={
                                    showBackButton && !formErrors && !showOtp
                                }
                                handlePrevStep={handlePrevStep}
                                backButton={backButton}
                                onModalCloseClick={() => {
                                    toggleFormModal(false);
                                    onModalClickClose
                                        ? onModalClickClose()
                                        : null;
                                }}
                                tcpa={tcpa}
                            >
                                <div
                                    style={{
                                        transform: `translate(${slideFormValue}%, 0)`,
                                    }}
                                    className={`${styles["steps-wrapper"]} `}
                                    id="stepsWrapper"
                                >
                                    {steps?.map((step, stepIndex) => (
                                        <FormStep
                                            isSecondServiceForm={
                                                isSecondServiceForm
                                            }
                                            operation={operation}
                                            formButtonClassName={
                                                classes?.formButtonClassName
                                            }
                                            isFormInsideOffersModal={
                                                isFormInsideOffersModal
                                            }
                                            formErrors={formErrors}
                                            key={stepIndex}
                                            step={step}
                                            index={stepIndex}
                                            handleFieldBlur={handleFieldBlur}
                                            handleFieldChange={
                                                handleFieldChange
                                            }
                                            currentStep={currentStep}
                                            isLoading={isLoading}
                                            formError={formError}
                                            fieldsData={fieldsData}
                                            steps={steps}
                                            visitedSteps={visitedSteps}
                                            {...props}
                                            hideStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.hideTitle ?? false
                                                    : false
                                            }
                                            hideStepDescription={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.hideDescription ??
                                                      false
                                                    : false
                                            }
                                            forceStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.forceStepTitle
                                                    : false
                                            }
                                            checkIfStepHasOneField={
                                                checkIfStepHasOneField
                                            }
                                            formSubmitted={formSubmitted}
                                            formId={formId}
                                            form={finalForm}
                                            overrideStepTitle={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.title
                                                    : ""
                                            }
                                            overrideStepDescription={
                                                stepsConfig
                                                    ? stepsConfig[stepIndex]
                                                          ?.description
                                                    : ""
                                            }
                                            stepStatus={stepStatus}
                                            textBlocks={form?.textBlocks ?? []}
                                            updateSecondServices={
                                                updateSecondServices
                                            }
                                            tags={tags}
                                            activeSecondServices={
                                                activeSecondServices
                                            }
                                            secondServiceLoading={
                                                secondServiceLoading
                                            }
                                            showPiiCompletionOtherFields={
                                                showPiiCompletionOtherFields
                                            }
                                            setShowPiiCompletionOtherFields={
                                                setShowPiiCompletionOtherFields
                                            }
                                            searchableMultiSelect={
                                                searchableMultiSelect
                                            }
                                            answerSummaryConfigs={
                                                finalAnswerSummaryConfigs
                                            }
                                        />
                                    ))}

                                    {/* Start Form Errors Step */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* Start all steps errors */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {(formErrors ||
                                                    (formErrorsResolved &&
                                                        goToThankYouPage &&
                                                        !otp?.isRequired)) && (
                                                    <>
                                                        <div
                                                            className={`${styles["warningMessage"]} rounded-sm`}
                                                            id="all-errors"
                                                        >
                                                            <h1
                                                                className={`text-base sm:text-lg flex items-start sm:items-center justify-start sm:justify-center`}
                                                            >
                                                                <span className="px-1 pt-1 sm:pt-0">
                                                                    <WarningAlertIcon />
                                                                </span>{" "}
                                                                <span>
                                                                    Your
                                                                    Submission
                                                                    Has a Few
                                                                    Errors
                                                                </span>
                                                            </h1>
                                                            <p
                                                                className={`${styles["description"]}  text-xs sm:text-base `}
                                                            >
                                                                You are almost
                                                                done! Please
                                                                correct the
                                                                following fields
                                                                so we can
                                                                process your
                                                                request:
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={`${
                                                                styles["fields"]
                                                            } ${
                                                                classes?.fieldsWrapperClassName ??
                                                                ""
                                                            }`}
                                                        >
                                                            {invalidFields.map(
                                                                (field) => (
                                                                    <FormField
                                                                        key={
                                                                            field.codeName
                                                                        }
                                                                        field={
                                                                            field as Field
                                                                        }
                                                                        fields={
                                                                            isExpandable(
                                                                                field as Field,
                                                                            )
                                                                                ? getExpandableFields(
                                                                                      field as Field,
                                                                                  )
                                                                                : []
                                                                        }
                                                                        asTitle={
                                                                            false
                                                                        }
                                                                        {...props}
                                                                        handleFieldBlur={
                                                                            handleFieldBlur
                                                                        }
                                                                        handleFieldChange={
                                                                            handleFieldChange
                                                                        }
                                                                        fieldsData={
                                                                            fieldsData
                                                                        }
                                                                        form={
                                                                            finalForm
                                                                        }
                                                                        searchableMultiSelect={
                                                                            searchableMultiSelect
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                            {formErrorsResolved &&
                                                                goToThankYouPage &&
                                                                formErrors ===
                                                                    0 && (
                                                                    <div className="text-center">
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-1"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-2"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-2"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    "line-3"
                                                                                ]
                                                                            }
                                                                        ></div>
                                                                    </div>
                                                                )}
                                                        </div>

                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    colors.primaryColor,
                                                            }}
                                                            className={`${
                                                                styles[
                                                                    "submit-button"
                                                                ]
                                                            } ${
                                                                classes?.formButtonClassName ??
                                                                ""
                                                            } ${
                                                                isLastStep
                                                                    ? classes?.formSubmitButtonClassName ??
                                                                      ""
                                                                    : ""
                                                            }`}
                                                            type="submit"
                                                        >
                                                            {operation?.loadingBuyers ||
                                                            isLoading ||
                                                            (isLastStep &&
                                                                formSubmitted) ? (
                                                                <Loader
                                                                    size={30}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <span>
                                                                        Submit
                                                                    </span>
                                                                    {currentStep ===
                                                                        0 && (
                                                                        <span
                                                                            className={`${styles["arrow"]}`}
                                                                        >
                                                                            <ArrowRightIcon />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            {/* End all steps errors */}
                                        </div>
                                    </div>
                                    {/* End Form Errors Step */}

                                    {/* Start OTP step */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* otp step */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {showOtp && (
                                                    <OtpForm
                                                        phoneNumber={
                                                            fieldsData
                                                                ?.phoneNumber
                                                                .value
                                                        }
                                                        colors={colors}
                                                        classes={classes}
                                                        isLoading={
                                                            !!(
                                                                isLoading ||
                                                                operation?.loadingBuyers
                                                            )
                                                        }
                                                        otp={otp}
                                                        handleFieldChange={
                                                            handleFieldChange
                                                        }
                                                    />
                                                )}
                                            </div>

                                            {/* otp step */}
                                        </div>
                                    </div>
                                    {/* End OTP step */}

                                    {/* Start Buyers List step */}
                                    <div className={styles["step"]}>
                                        <div>
                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {operation?.key ===
                                                    "ROUND_TWO" && (
                                                    <BuyersList
                                                        dispatch={dispatch}
                                                        operation={operation}
                                                        buttonText={
                                                            submitButtonText
                                                        }
                                                        isLoading={isLoading}
                                                        handleSubmit={
                                                            startSecondRoundBidding
                                                        }
                                                        buttonClassName={
                                                            classes?.formButtonClassName
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Buyers List step */}

                                    {/* Start Thank you step */}
                                    <div className={styles["step"]}>
                                        <div>
                                            {/* thank you step */}

                                            <div
                                                className={
                                                    styles["extra-step-inner"]
                                                }
                                            >
                                                {formSubmitted &&
                                                    !goToThankYouPage &&
                                                    !goToListPage && (
                                                        <>
                                                            {compactOfferBlock &&
                                                                !hideOffers && (
                                                                    <CompactOffer
                                                                        zone={
                                                                            affiliateOfferZones.THANK_YOU
                                                                        }
                                                                        showAllOffers
                                                                    />
                                                                )}

                                                            {customThankYouStep ?? (
                                                                <ThankYou
                                                                    inForm={
                                                                        true
                                                                    }
                                                                    className={
                                                                        classes?.innerThankYouClassName
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                            </div>

                                            {/* thank you step */}
                                        </div>
                                    </div>
                                    {/* End Extra steps */}
                                </div>
                            </FieldWrapper>
                            {jornayaIdElement}

                            {sellingPoint &&
                                !isLastStep &&
                                (sellingPointsSteps === "ALL_STEPS" ||
                                    (sellingPointsSteps === "FIRST_STEP" &&
                                        currentStep === 0)) && (
                                    <div
                                        className={`${styles["sellingPoint"]} ${
                                            classes?.sellingPointClassName ?? ""
                                        }`}
                                    >
                                        {sellingPoint}
                                    </div>
                                )}

                            {showProgress &&
                                !otp?.isRequired &&
                                !formErrors &&
                                !showOfferInForm &&
                                !showFormModal &&
                                operation?.key !== "ROUND_TWO" &&
                                !stepsConfig?.[currentStep]?.hideProgress && (
                                    <ProgressBar
                                        className={`${styles["progress-bar"]} ${
                                            classes?.formProgressbarClassName ??
                                            ""
                                        }`}
                                        current={currentStep}
                                        total={
                                            formSubmitted
                                                ? steps?.length
                                                : steps?.length + 1
                                        }
                                        color={colors?.progressBar}
                                        shift={progressShift}
                                        showStepNumberOnly={showStepNumberOnly}
                                    />
                                )}
                            {showOfferInForm &&
                                (formTemplate === "v2" ||
                                    formTemplateProp === "v2") && (
                                    <OffersInForm
                                        offer={
                                            formOffer[0]
                                                ? formOffer[0]
                                                : undefined
                                        }
                                        shape={
                                            (formTemplate as string) ||
                                            formTemplateProp
                                        }
                                    />
                                )}

                            {!showFormModal && tcpa}

                            {/* +1 is for thank you step */}

                            {showBackButton &&
                                !formErrors &&
                                !showOtp &&
                                !showFormModal &&
                                operation?.key !== "ROUND_TWO" && (
                                    <button
                                        type="button"
                                        className={`${styles["back-button"]} ${
                                            backButton?.class ?? ""
                                        }`}
                                        onClick={() =>
                                            backButton?.firstStepAction &&
                                            currentStep === 0
                                                ? backButton.firstStepAction()
                                                : handlePrevStep()
                                        }
                                    >
                                        {backButton?.label ?? "Previous"}
                                        <i className={styles["arrow-back"]}></i>
                                    </button>
                                )}

                            <Modal
                                active={offersPopupActive}
                                onClose={() => {
                                    setOffersPopupActive(false);
                                    if (showRegulations) {
                                        setClicksOffers([]);
                                        setHasRegulations(false);
                                    }
                                    if (showCustomListingModal) {
                                        setPopupOffers([]);
                                        setShowCustomListingModal(false);
                                    }
                                }}
                                closeIcon={<CloseIcon />}
                                width={modalSize ?? modalWidth}
                                modalClassName={styles["form-offers-popup"]}
                                pageBuilderVariables={
                                    modalVariables as CSSProperties
                                }
                            >
                                {modalOffersContent}
                                {modalOfferClicksContent}
                            </Modal>
                            {showFormInsideOffersModal() && (
                                <Modal
                                    active={showFromInExitModal}
                                    onClose={() => {
                                        handleCloseFormInsideExitModal();
                                    }}
                                    closeIcon={<CloseIcon />}
                                    width={1000}
                                    modalClassName={
                                        styles["form-inside-offers-container"]
                                    }
                                    pageBuilderVariables={
                                        modalVariables as CSSProperties
                                    }
                                >
                                    {showFromInExitModal && (
                                        <FormOfferModal {...props} />
                                    )}
                                </Modal>
                            )}
                        </form>
                    </>
                ) : null}
            </>
        );
    }

    return <></>;
}
