import { cloneDeep } from "lodash";
import { DomainForm } from "src/api";
import { FieldsData } from "./types";

export const useFields = (
    form: DomainForm,
): {
    fields: FieldsData;
} => {
    const clonedSteps = cloneDeep(form.steps);

    const temp: FieldsData = {};

    clonedSteps.forEach((step) => {
        step.fields.forEach((field) => {
            temp[field.codeName] = {
                value: field.defaultValue ?? "",
                valid: true,
                errorMessage: "",
                changed: false,
            };
        });
    });
    return { fields: temp };
};
