import { DomainForm } from "src/api";
import { FieldsData } from "./types";
import { Step } from "@lib/shared/types";
import { useState } from "react";
import { cloneDeep, keyBy, mapValues } from "lodash";
import { decodeFieldsFromText } from "./utils";

export const useSteps = (
    form: DomainForm,
    fieldsState: FieldsData | null,
): { steps: Step[]; setSteps: (steps: Step[]) => void } => {
    const [clonedSteps, setClonedSteps] = useState(cloneDeep(form.steps));

    clonedSteps.forEach((step, stepIndex: number) => {
        clonedSteps[stepIndex].title = decodeFieldsFromText(
            step.title,
            fieldsState,
        );
        step.fields.forEach((field, fieldIndex) => {
            if (Array.isArray(clonedSteps[stepIndex].fields[fieldIndex].meta)) {
                clonedSteps[stepIndex].fields[fieldIndex].meta = mapValues(
                    keyBy(field.meta || [], "key"),
                    "value",
                );
            }
        });
    });

    return { steps: clonedSteps, setSteps: setClonedSteps };
};
