import { Field } from "@lib/shared/types";
import Checkbox from "../fields/checkbox";
import { Dispatch, useMemo, useState } from "react";
import {
    ActionTypes,
    Buyer,
    FormReducerAction,
    Operation,
} from "@hooks/useForm/types";
import styles from "./styles.module.scss";
import formStyles from "../styles.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import Loader from "@components/shared/loader";

export const BuyersList = ({
    dispatch,
    operation,
    isLoading,
    buttonText,
    buttonClassName,
    handleSubmit,
}: {
    dispatch: Dispatch<FormReducerAction>;
    operation: Operation | null;
    isLoading: boolean;
    buttonText?: string;
    buttonClassName?: string;
    handleSubmit: ({
        selectedBuyers,
        consentLanguage,
        hasDomainConsent,
    }: {
        selectedBuyers: Buyer[];
        consentLanguage: string;
        hasDomainConsent: boolean;
    }) => Promise<void>;
}) => {
    const { domain } = useDomainContext();
    const [hasDomainConsent, setHasDomainConsent] = useState(true);
    const field: Field = {
        label: "Buyers List",
        codeName: "buyersList",
        defaultValues: {
            options: [
                ...(operation?.secondRoundBuyers?.map((buyer) => ({
                    id: parseInt(buyer.buyerId as string),
                    icon: {
                        fullPath: buyer.logo,
                    },
                    label: buyer.displayName ?? buyer.buyerName,
                    value: String(buyer.buyerId),
                })) ?? []),
            ],
        },
        defaultValue: null,
        dataDependency: null,
        fieldType: "checkbox",
        placeholder: null,
        isRequired: true,
        pattern: null,
        errorMessage: null,
        minValue: null,
        maxValue: null,
        note: null,
        autocomplete: null,
        title: null,
        mask: null,
        meta: null,
        niceName: null,
        dependency: null,
        validationType: "zipCode",
        conditionalLogic: null,
    };
    const domainBuyerField: Field = {
        label: domain.niceName || domain.name,
        codeName: "hasDomainConsent",
        defaultValues: {
            options: [
                {
                    label: domain.niceName ?? domain.name,
                    value: "domain",
                    id: 0,
                    icon: {
                        fullPath: domain.logo?.fullPath ?? "",
                    },
                },
            ],
        },
        defaultValue: null,
        dataDependency: null,
        fieldType: "checkbox",
        placeholder: null,
        isRequired: true,
        pattern: null,
        errorMessage: null,
        minValue: null,
        maxValue: null,
        note: null,
        autocomplete: null,
        title: null,
        mask: null,
        meta: null,
        niceName: null,
        dependency: null,
        validationType: "zipCode",
        conditionalLogic: null,
    };
    const selectAllField: Field = {
        label: "Select All",
        codeName: "selectAll",
        defaultValues: {
            options: [
                {
                    id: 1,
                    label: "Select All - Maximize your choices!",
                    value: "selectAll",
                    icon: null,
                },
            ],
        },
        defaultValue: null,
        dataDependency: null,
        fieldType: "checkbox",
        placeholder: null,
        isRequired: true,
        pattern: null,
        errorMessage: null,
        minValue: null,
        maxValue: null,
        note: null,
        autocomplete: null,
        title: null,
        mask: null,
        meta: null,
        niceName: null,
        dependency: null,
        validationType: "zipCode",
        conditionalLogic: null,
    };

    const allBuyersSelected = useMemo(() => {
        return (
            operation?.secondRoundBuyers?.every((buyer) => buyer.selected) &&
            (operation?.secondRoundBuyers?.length ?? 0) > 0
        );
    }, [operation?.secondRoundBuyers]);

    const handleSelectAll = () => {
        if (allBuyersSelected) {
            dispatch({
                type: ActionTypes.SET_OPERATION,
                payload: {
                    secondRoundBuyers: operation?.secondRoundBuyers?.map(
                        (buyer) => ({
                            ...buyer,
                            selected: false,
                        }),
                    ),
                },
            });
            if (!operation?.hasDomainConsent) {
                setHasDomainConsent(false);
            }
        } else {
            dispatch({
                type: ActionTypes.SET_OPERATION,
                payload: {
                    secondRoundBuyers: operation?.secondRoundBuyers?.map(
                        (buyer) => ({
                            ...buyer,
                            selected: true,
                        }),
                    ),
                },
            });
            if (!operation?.hasDomainConsent) {
                setHasDomainConsent(true);
            }
        }
    };

    const { category } = useDomainContext();

    const handleFieldChange = (val: string) => {
        dispatch({
            type: ActionTypes.SET_OPERATION,
            payload: {
                secondRoundBuyers: operation?.secondRoundBuyers?.map(
                    (buyer) => ({
                        ...buyer,
                        selected: val.includes(String(buyer.buyerId)),
                    }),
                ),
            },
        });
    };
    const handleDomainFieldChange = (val: string) => {
        setHasDomainConsent(!!val);
    };

    return (
        <div>
            <h2 className={"text-2xl font-bold mb-4 " + styles["title"]}>
                Compare {category?.label ?? category?.name} Providers for
                Tailored Deals
            </h2>
            <p className={"font-light mb-4 " + styles["description"]}>
                Choose Providers to Receive Personalized Quotes Tailored to Your
                Needs. Selecting All Providers Unlocks More Options and Special
                Offers Just For You!
            </p>

            <div className="mb-10">
                {(operation?.secondRoundBuyers?.length ?? 0) > 1 && (
                    <div className="flex items-end my-4">
                        <Checkbox
                            onChange={() => handleSelectAll()}
                            field={selectAllField}
                            value={allBuyersSelected ? "selectAll" : ""}
                            className={styles["select-all-checkbox-field"]}
                            primaryColor={"black"}
                            ignoreOptionIcons={true}
                        />
                    </div>
                )}
                <Checkbox
                    onChange={(val: string) => handleFieldChange(val)}
                    field={field}
                    value={
                        operation?.secondRoundBuyers
                            ?.filter((buyer) => buyer.selected)
                            ?.map((buyer) => buyer.buyerId)
                            .join(",") ?? ""
                    }
                    className={
                        styles["checkbox"] + " " + styles["checkbox-field"]
                    }
                    primaryColor={"black"}
                    iconLocation={"end"}
                    iconClassName={styles["checkbox-icon"]}
                    addConsentTags
                />
                {!operation?.hasDomainConsent && (
                    <Checkbox
                        onChange={(val: string) => handleDomainFieldChange(val)}
                        field={domainBuyerField}
                        value={hasDomainConsent ? "domain" : ""}
                        className={
                            styles["checkbox"] + " " + styles["checkbox-field"]
                        }
                        primaryColor={"black"}
                        iconLocation={"end"}
                        iconClassName={styles["checkbox-icon"]}
                        addConsentTags
                    />
                )}
            </div>

            <button
                className={`${formStyles["submit-button"]} ${
                    buttonClassName ?? ""
                } mt-20`}
                disabled={
                    operation?.secondRoundBuyers?.every(
                        (buyer) => !buyer.selected,
                    ) || isLoading
                }
                type="button"
                onClick={async () => {
                    const tcpaText = document.querySelector(
                        '[data-tf-element-role="consent-language"]',
                    ) as HTMLLabelElement;

                    await handleSubmit({
                        selectedBuyers:
                            operation?.secondRoundBuyers?.filter(
                                (buyer) => buyer.selected,
                            ) ?? [],
                        consentLanguage: tcpaText?.innerHTML ?? "",
                        hasDomainConsent: !!hasDomainConsent,
                    });
                }}
                data-tf-element-role="submit"
            >
                {isLoading ? (
                    <Loader size={30} />
                ) : (
                    <>
                        <span>{buttonText}</span>
                    </>
                )}
            </button>
        </div>
    );
};
