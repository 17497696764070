import { useEffect, useRef } from "react";
import { InitState } from "./types";

export function useNext(value: InitState): () => Promise<InitState> {
    const valueRef = useRef(value);
    const resolvesRef = useRef<((value: InitState) => void)[]>([]);

    useEffect(() => {
        if (valueRef.current !== value) {
            for (const resolve of resolvesRef.current) {
                resolve(value);
            }
            resolvesRef.current = [];
            valueRef.current = value;
        }
    }, [value]);

    return () =>
        new Promise((resolve) => {
            resolvesRef.current = [...resolvesRef.current, resolve];
        });
}
