import { FieldsData } from "./types";

export const decodeFieldsFromText = (
    text: string,
    fieldsState: FieldsData | null,
) => {
    let clonedText = text;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);

    dynamicStrings?.forEach((item) => {
        if (fieldsState?.[item.replace("${", "").replace("}", "")]?.value) {
            const temp = clonedText.replace(
                item,
                fieldsState[item.replace("${", "").replace("}", "")]?.value,
            );
            clonedText = temp;
        }
    });

    return clonedText;
};
